import SubmissionForm from "../components/SubmissionForm";
import tru from "../assets/toys_r_us.png";
import ab from "../assets/anybodies.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import { customFirebaseRequest } from "../Services/FirebaseFuncs";

enum State {
  Loading = 0,
  Available = 1,
  Submited = 2,
  NotExist = 3,
}

const SubmissionPage = () => {
  const params: any = useParams();

  const [state, setState] = useState(State.Loading);
  const [spots, setSpots] = useState(0);
  const [walletAddresses, setWalletAddresses] = useState([]);

  useEffect(() => {
    if (params?.submissionId) {
      customFirebaseRequest("getWhitelistSubmissionStatus", {
        submissionId: params?.submissionId,
      })
        .then((response) => {
          if (!response?.exist) {
            setState(State.NotExist);
            return;
          }
          setSpots(response?.spots);
          setWalletAddresses(response?.walletAddresses);
          setState(response?.submitted ? State.Submited : State.Available);
        })
        .catch((error) => {
          notification["error"]({
            message: `Something wents wrong`,
            description: <>{error}</>,
            placement: "top",
          });
        });
    }
  }, [params?.submissionId]);

  const render = () => {
    switch (state) {
      case State.NotExist:
        return <h1 className="text-center mt-2em">Submission not exist</h1>;
      case State.Available:
      case State.Submited:
        return (
          <>
            <div className="collab-title">
              {state === State.Submited
                ? "SUCCESS! :)"
                : "PRESALE SUBMISSION FORM"}
            </div>
            {state === State.Submited && (
              <h3 className="text-center mb-0_5em">
                You have successfully submitted the following wallet addresses
                for presale:
              </h3>
            )}
            <SubmissionForm
              disabled={state === State.Submited}
              submissionId={params?.submissionId}
              spots={spots}
              walletAddresses={walletAddresses}
            />
          </>
        );
      default:
        return (
          <div className="spinner-container">
            <Spin size="large" />
          </div>
        );
    }
  };

  return (
    <div className="main-layout-form-container">
      <div className="main-intro-container">
        <div className="main-collabers-containers">
          <img className="tru" src={tru} alt="" />
          <div className="collab-x">X</div>
          <img className="anybodies" src={ab} alt="" />
        </div>
        {render()}
      </div>
    </div>
  );
};

export default SubmissionPage;
