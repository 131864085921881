import { Button, Divider, Form, Input, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { notification } from "antd";
import { customFirebaseRequest } from "../../Services/FirebaseFuncs";
import Checkbox from "antd/lib/checkbox/Checkbox";

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

interface Props {
  disabled: boolean;
  submissionId: string;
  spots: number;
  walletAddresses: string[];
}

const SubmissionForm = ({
  disabled,
  submissionId,
  spots,
  walletAddresses,
}: Props) => {
  const [checked, setChecked] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    if (typeof disabled !== "undefined" && spots && walletAddresses) {
      const arr: any = [];
      for (var i = 1; i <= spots; i++) {
        arr.push({
          name: `wallet${i}`,
          label: `Wallet address ${i}`,
          type: "input",
          initialValue: walletAddresses?.[i - 1] || "",
        });
      }

      const initial: any = {};
      walletAddresses.forEach((address, index) => {
        initial[`wallet${index + 1}`] = address;
      });
      setInitialValues(initial);
      setFields(arr);
    }
  }, [disabled, spots, walletAddresses]);

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    setSubmitting(true);
    const formData = [];
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element) {
          formData.push(element);
        }
      }
    }
    if (formData.length === 0) {
      notification["error"]({
        message: `Form not submited`,
        description: <>At least one field must be filled</>,
        placement: "top",
      });
      setSubmitting(false);
      return;
    }

    customFirebaseRequest("submitWhitelistSubmission", {
      submissionId,
      walletAddresses: formData,
    })
      .then((response) => {
        if (!response?.success) {
          throw new Error(response.message);
        }
        setSubmited(true);
      })
      .catch((error) => {
        notification["error"]({
          message: `Something wents wrong`,
          description: <>{error}</>,
          placement: "top",
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="main-wl-form-container">
      <div className="main-wl-form">
        {submited ? (
          <Result
            status="success"
            title="Successfully submitted your application!"
            subTitle="Wallets saved successfully"
          />
        ) : (
          fields.length > 0 && (
            <Form
              {...layout}
              form={form}
              disabled={disabled || submitting}
              name="control-hooks"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              {fields.map((field: any) => {
                return (
                  <Form.Item
                    key={field.label}
                    name={field.name}
                    label={field.label}
                  >
                    <Input />
                  </Form.Item>
                );
              })}
              {!disabled && (
                <div className="submission-container">
                  <Divider style={{ marginTop: "50px" }} />
                  <Checkbox
                    style={{ margin: "auto" }}
                    disabled={submitting}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  >
                    I accept that once submitting this form, I cannot change the
                    wallet addresses qualified for presale
                  </Checkbox>

                  <div className="submission-container">
                    <Button
                      size="large"
                      type="primary"
                      target="_blank"
                      htmlType="submit"
                      loading={submitting}
                      disabled={!checked || submitting}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )
        )}
      </div>
    </div>
  );
};

export default SubmissionForm;
