import "./CollabForm.scss";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Result,
  Spin,
  Statistic,
  Tabs,
  Tooltip,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { notification } from "antd";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { checkWalletBalance } from "../../Services/BalanceChecker";
import { sendLocalTransactions } from "../../Services/TransactionBuilder";
import { Transaction } from "@solana/web3.js";
import * as axios from "axios";
import TextArea from "antd/lib/input/TextArea";

const COLLAB_STYLE_AMOUNT = 5_000;
const WL_STYLE_AMOUNT = 100;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};
const collabFields = [
  {
    name: "projectName",
    label: "Project Name",
    type: "input",
    help: "",
  },
  {
    name: "projectDiscordInvite",
    label: "Project Discord Invite",
    type: "input",
    help: "",
  },

  {
    name: "contactName",
    label: "Contact Name",
    type: "input",
    help: "Point of contact for collab",
  },
  {
    name: "NFTCollectionSize",
    label: "NFT Collection Size",
    type: "input",
    help: "",
  },
  {
    name: "discordUsername",
    label: "Discord username of contact",
    type: "input",
    help: "",
  },
  {
    name: "twitterUsername",
    label: "Twitter username of contact",
    type: "input",
    help: "",
  },
  // {
  //   name: "refundWalletAddress",
  //   label: "Refund Wallet Address",
  //   type: "input",
  //   help: "Wallet address for refund incase they are not picked",
  // },
];

const wlfields = [
  {
    name: "discordUsername",
    label: "Discord username",
    type: "input",
    help: "",
  },
  {
    name: "twitterUsername",
    label: "Twitter username",
    type: "input",
    help: "",
  },
  {
    name: "walletAddress",
    label: "Presale Wallet Address",
    type: "input",
    help: "The SOL wallet address that will receive the presale spot",
  },
];

export const CollabForm = () => {
  const [checked, setChecked] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [type, setType] = useState("Collab");
  const submissionLinkInputRef = useRef<any>();

  const { publicKey, signTransaction } = useWallet();
  const [form] = Form.useForm();

  const handleFormSelection = (e: any) => {
    console.log(e);
    setType(e);
  };

  const isCollab = () => type === "Collab";

  const onFinish = async (values: any) => {
    setSubmitting(true);
    if (!publicKey || !signTransaction) {
      setSubmitting(false);
      return;
    }
    console.log(values);
    const hasEnough = await checkWalletBalance({
      solAmount: 0.001,
      walletAddress: publicKey.toString(),
      tokens: [
        {
          address: "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
          name: "STYLE",
          amount: isCollab() ? COLLAB_STYLE_AMOUNT : WL_STYLE_AMOUNT,
        },
      ],
    });

    if (hasEnough) {
      try {
        const transaction = (await sendLocalTransactions(
          [],
          [
            {
              amount: isCollab()
                ? COLLAB_STYLE_AMOUNT * 1_000_000_000
                : WL_STYLE_AMOUNT * 1_000_000_000,
              fromAddress: publicKey.toString(),
              toAddress: "FyGEBatm9d8oGM3mS4qCNcVU1oUWkpjcNEfVPfb7quaR",
              tokenAddress: "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
            },
          ],
          publicKey.toString(),
          signTransaction,
          (update, message) => {
            console.log(update, message);
          },
          true
        )) as Transaction;

        const res = await customFirebaseRequest(
          "processCollabSubmission",
          {
            walletAddress: publicKey.toString(),
            transactionInfo: transaction.serialize(),
            submissionType: type,
            formData: values,
          }
        );
        console.log(res);
        if (res.success) {
          setResponse(res);
        } else {
          notification["error"]({
            message: `Failed to sumbit`,
            description: (
              <>
                There was an issue submitting your request.
                <br />
                Please try again later.
              </>
            ),
            placement: "top",
          });
        }
        // const { data } = await buyRaffleTickets(
        //   await user.getIdToken(),
        //   raffleDoc.id,
        //   {
        //     bookBurner: currency !== 1,
        //     numTickets: tickets,
        //     trans: transaction.serialize(),
        //     walletAddress: publicKey.toString(),
        //   }
        // );
        //   await addPurchaseListener!(data.buyTicketRequest);
      } catch (err) {
        console.error(err);
      }
    } else {
    }
    setSubmitting(false);
  };

  const getRequestId = () => response.submissionPath.split("/").pop();

  return (
    <>
      <div className="main-wl-form-container">
        <div className="main-wl-form">
          {response && (
            <Result
              status="success"
              title="Successfully submitted your application!"
              extra={[
                <Alert
                  key={1}
                  message={
                    <>
                      <h2>
                        Your order ref: <b>{getRequestId()}</b>
                      </h2>
                      <h3>Don't lose the link to the submission form</h3>
                      <Input.Group compact>
                        <Input
                          ref={submissionLinkInputRef}
                          style={{ width: "calc(100% - 100px)" }}
                          defaultValue={`${
                            window.location.href
                          }submission/${getRequestId()}`}
                        />
                        <Tooltip title="Copy submission link">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                              submissionLinkInputRef.current.select();
                              document.execCommand("copy");

                              notification["success"]({
                                message: `Submission link copied`,
                                placement: "top",
                              });
                            }}
                          />
                        </Tooltip>
                      </Input.Group>
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          window.open(
                            window.location.href +
                              `submission/${getRequestId()}`,
                            "_blank"
                          );
                        }}
                        className="mt-1em"
                      >
                        Go to submission
                      </Button>
                    </>
                  }
                  type="warning"
                />,
              ]}
            />
          )}
          {!response && (
            <Form
              {...layout}
              form={form}
              disabled={submitting}
              name="control-hooks"
              onFinish={onFinish}
            >
              {/* <Divider /> */}
              <Tabs
                defaultActiveKey="1"
                centered
                onChange={handleFormSelection}
              >
                {/* <Tabs.TabPane tab="Individual" key="WhiteList" /> */}
                <Tabs.TabPane tab="Project Collab" key="Collab" />
              </Tabs>

              <div className="collab-desc">
                {isCollab()
                  ? `Projects that apply will stand a chance to create
                physical toys based on their NFT collection!`
                  : `Individuals can apply for a presale spot to mint a Toys "R" Us NFT at a discounted rate.`}
              </div>

              {(isCollab() ? collabFields : wlfields).map(
                ({ name, label, help }) => {
                  return (
                    <Form.Item
                      key={name}
                      name={name}
                      label={label}
                      help={help}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  );
                }
              )}
              {isCollab() && (
                <Form.Item
                  name={"why"}
                  label="Why you?"
                  help={"What makes your project special?"}
                  rules={[{ required: true }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              )}
              <Divider style={{ marginTop: "50px" }} />

              <div className="submission-container">
                {isCollab() && (
                  <h5 className="text-center">
                    By submitting this form, the project will automatically
                    receive 30 presale spots for their community to mint at a
                    discounted rate
                  </h5>
                )}
                <Checkbox
                  style={{ margin: "auto" }}
                  disabled={submitting}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                >
                  I accept the{" "}
                  <a
                    target={"_blank"}
                    href="https://docs.google.com/document/d/1HWy6p8X-VAo1t7eqWyiM_U9ooyFJCKDkXRT0GXHAuq4/edit?usp=sharing"
                  >
                    terms and conditions
                  </a>
                </Checkbox>
                {checked && <WalletMultiButton />}
                {!submitting && checked && publicKey && (
                  <>
                    <Divider />
                    <Statistic
                      title="Submission Fee"
                      value={`${
                        isCollab() ? COLLAB_STYLE_AMOUNT : WL_STYLE_AMOUNT
                      } $STYLE`}
                    />

                    <Button size="large" type="primary" htmlType="submit">
                      SUBMIT COLLAB REQUEST
                    </Button>
                    <Button
                      size="small"
                      type="dashed"
                      target="_blank"
                      href="https://bit.ly/3NKvWWw"
                    >
                      Get more $STYLE
                    </Button>
                  </>
                )}
                {submitting && <Spin />}
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    // axios.default.post<any>(`http://localhost:5000/nft-anybodies/us-central1/${functionName}`, {
    axios.default
      .post<any>(
        // `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
        `https://collab.anybodies.com/api/collab/v1/${functionName}`,
        {
          data,
        }
      )
      .then((response) => {
        // handle success
        // console.log("response", response.data);
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(undefined);
      });
  });
