import * as axios from "axios";

export const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    axios.default
      .post<any>(
        // `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
        `https://collab.anybodies.com/api/collab/v1/${functionName}`,
        {
          data,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        console.log(error);
        return resolve(undefined);
      });
  });
