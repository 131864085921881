import "./App.scss";
import { MainLayout } from "./MainLayout/MainLayout";
import { WalletService } from "./Services/WalletService/WalletService";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SubmissionPage from "./pages/SubmissionPage";
ConfigProvider.config({ theme: { primaryColor: "#ffeb3b" } });

export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}></Route>
      <Route
        path="/submission/:submissionId"
        element={<SubmissionPage />}
      ></Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

function App() {
  return (
    <div className="App">
      <WalletService>
        <ConfigProvider>
          <BrowserRouter>
            <RoutesComponent />
          </BrowserRouter>
        </ConfigProvider>
      </WalletService>
    </div>
  );
}

export default App;
