import "./MainLayout.scss";

import tru from "../assets/toys_r_us.png";
import ab from "../assets/anybodies.png";

import { CollabForm } from "./CollabForm/CollabForm";

export const MainLayout = () => {
  return (
    <div className="main-layout-form-container">
      <div className="main-intro-container">
        <div className="main-collabers-containers">
          <img className="tru" src={tru} alt="" />
          <div className="collab-x">X</div>
          <img className="anybodies" src={ab} alt="" />
        </div>
        <div className="collab-title">COLLAB FORM </div>
        {/* <div className="collab-desc">
          Individual & Projects that apply will stand a chance to create
          physical toys based on their NFT collection!
        </div> */}
        {/* <div className="collab-requirement">
          <div>Represent an NFT project</div>
          <div>|</div>
          <div>Have 5,000 $STYLE</div>
        </div> */}
        <CollabForm />
      </div>
      {/* <div className="main-layout-form"></div> */}
    </div>
  );
};
