import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { notification } from "antd";
import axios from "axios";
import { privateConnection, privateRPC } from "./TransactionBuilder";

type BalanceCheckerPayload = {
  walletAddress: string;
  solAmount?: number;
  tokens?: {
    name: string;
    address: string;
    amount: number;
  }[];
};

export const checkWalletBalance = async ({
  walletAddress,
  solAmount,
  tokens,
}: BalanceCheckerPayload) => {
  try {
    const publicKey = new PublicKey(walletAddress);
    if (solAmount) {
      try {
        const lamports = await privateConnection.getBalance(publicKey);
        const sol = lamports / LAMPORTS_PER_SOL;
        if (sol < solAmount) {
          notification["warning"]({
            message: `No enough SOL in your wallet.`,
            description: (
              <div>
                You have {sol.toFixed(4)} SOL in your wallet.
                <br /> You need to have {solAmount} SOL to complete this
                transaction.
              </div>
            ),
            placement: "top",
          });
          return false;
        }
      } catch (err) {
        notification["error"]({
          message: (
            <>
              Sorry, there was an issue get your SOL balance.
              <br />
              Please try again later.
            </>
          ),
          placement: "top",
        });
        return false;
      }
    }
    if (tokens) {
      return (
        (
          await Promise.all(
            tokens?.map(async ({ name, address, amount }) => {
              const decimals = await getTokenDeciamalPoint(address);
              const promptIssue = () =>
                notification["error"]({
                  message: (
                    <>
                      Sorry, there was an issue getting your ${name} balance.
                      <br />
                      Please try again later.
                    </>
                  ),
                  placement: "top",
                });
              if (decimals === undefined) {
                promptIssue();
                return false;
              }
              const tokens = await getRewardTokenBalanceInVault(
                walletAddress,
                address
              );
              if (tokens === undefined) {
                promptIssue();
                return false;
              }
              const bAmount = tokens / Math.pow(10, decimals);
              if (bAmount < amount) {
                notification["error"]({
                  message: `Not enough $${name}`,
                  description: (
                    <>
                      You have {bAmount.toFixed(4)} ${name} in your wallet.
                      <br /> You need to have {amount} ${name} to complete this
                      transaction.
                    </>
                  ),
                  placement: "top",
                });
                return false;
              }
              return true;
            })
          )
        ).filter((res) => !res).length === 0
      );
    }
  } catch (err) {
    console.log(err);
    notification["error"]({
      message: (
        <>
          Sorry, there was an issue with your wallet.
          <br />
          Please try again later.
        </>
      ),
      placement: "top",
    });
    return false;
  }
  return true;
};

export const getRewardTokenBalanceInVault = async (
  walletAddress: string,
  tokenAddress: string
) => {
  try {
    const response = await axios({
      url: privateRPC,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: [
        {
          jsonrpc: "2.0",
          id: 1,
          method: "getTokenAccountsByOwner",
          params: [
            walletAddress,
            {
              mint: tokenAddress,
            },
            {
              encoding: "jsonParsed",
            },
          ],
        },
      ],
    });

    return (
      +response?.data?.[0]?.result?.value?.[0]?.account?.data?.parsed?.info
        ?.tokenAmount?.amount || 0
    );
  } catch {
    return undefined;
  }
};

export const getTokenDeciamalPoint = async (tokenAddress: string) => {
  try {
    const response = await axios({
      url: privateRPC,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        jsonrpc: "2.0",
        id: 1,
        method: "getAccountInfo",
        params: [
          tokenAddress,
          {
            encoding: "jsonParsed",
          },
        ],
      },
    });
    if (response?.data?.error) return undefined;
    return response?.data?.result?.value?.data?.parsed?.info?.decimals;
  } catch (err) {
    console.error("Get decial point err", err);
    return undefined;
  }
};
