import {
  createTransferInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  sendAndConfirmRawTransaction,
} from "@solana/web3.js";

export const privateRPC =
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/";

export const privateConnection = new Connection(
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/",
  "confirmed"
);

type SendLocalTransactionStatus =
  | "STARTED"
  | "CREATING_ATA"
  | "CONFIRMING_ATA"
  | "FAILED_ATA"
  | "CREATING_TRANSACTION"
  | "CONFIRMING_TRANSACTION"
  | "FAILED_TRANSACTION"
  | "DONE";

export const sendLocalTransactions = async (
  SOLTransfers: { fromAddress: string; toAddress: string; solAmount: number }[],
  SPLTransfers: {
    fromAddress: string;
    toAddress: string;
    tokenAddress: string;
    amount: number;
  }[],
  feePayerAddress: string,
  signTransaction: any,
  dispatchUpdate: (status: SendLocalTransactionStatus, err?: any) => void,
  returnTransaction: boolean,
  attempt = 0,
  maxAttemps = 5
): Promise<Transaction | string | null> => {
  dispatchUpdate("STARTED");
  try {
    if (attempt === maxAttemps) {
      return null;
    }
    const instructions: TransactionInstruction[] = [];
    const feePayer = new PublicKey(feePayerAddress);
    dispatchUpdate("CREATING_ATA");
    const didCreateAtas = await createAtaIfNeeded(
      SPLTransfers,
      feePayerAddress,
      signTransaction,
      dispatchUpdate
    );
    if (!didCreateAtas) return null;
    dispatchUpdate("CREATING_TRANSACTION");

    for (const transfer of SOLTransfers) {
      instructions.push(
        SystemProgram.transfer({
          fromPubkey: new PublicKey(transfer.fromAddress),
          toPubkey: new PublicKey(transfer.toAddress),
          lamports: +(transfer.solAmount * LAMPORTS_PER_SOL).toFixed(0),
        })
      );
    }
    for (const {
      fromAddress,
      toAddress,
      tokenAddress,
      amount,
    } of SPLTransfers) {
      const mintPublicKey = new PublicKey(tokenAddress);
      const fromWallet = new PublicKey(fromAddress);
      const toWallet = new PublicKey(toAddress);

      const fromATA = await getATA({
        mintPublicKey,
        ownerPublicKey: fromWallet,
      });
      const toATA = await getATA({
        mintPublicKey,
        ownerPublicKey: toWallet,
      });
      if (fromATA && toATA) {
        instructions.push(
          createTransferInstruction(
            fromATA,
            toATA,
            fromWallet,
            +amount.toFixed(0),
            []
          )
        );
      }
    }

    if (instructions.length > 0) {
      const transaction = new Transaction().add(...instructions);
      transaction.feePayer = feePayer;
      const blockhash = await privateConnection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash.blockhash;
      transaction.lastValidBlockHeight = blockhash.lastValidBlockHeight;
      dispatchUpdate("CONFIRMING_TRANSACTION");

      const signedTransaction: Transaction = await signTransaction(transaction);

      if (returnTransaction) {
        return signedTransaction;
      }
      const txid = await sendAndConfirmRawTransaction(
        privateConnection,
        signedTransaction.serialize(),
        // {
        //   blockhash: blockhash.blockhash,
        //   lastValidBlockHeight: blockhash.lastValidBlockHeight,
        //   "signature":signedTransaction.so.
        // },
        {
          commitment: "confirmed",
        }
      );

      dispatchUpdate("DONE");
      return txid;

      // const confirmed = await privateConnection.confirmTransaction(
      //   {
      //     signature: txid,
      //     blockhash: blockhash.blockhash,
      //     lastValidBlockHeight: blockhash.lastValidBlockHeight,
      //   },
      //   "finalized"
      // );
      // console.log("Finished creating atas", txid, confirmed);
      // if (confirmed?.value?.err === null) {
      //   return txid;
      // } else {
      //   return null;
      // }
    } else {
      dispatchUpdate("FAILED_TRANSACTION");
      return null;
    }
  } catch (err) {
    dispatchUpdate("FAILED_TRANSACTION");
    console.log("createLocalTransactions", err);
    return null;
  }
};

export const createAtaIfNeeded = async (
  SPLTransfers: {
    fromAddress: string;
    toAddress: string;
    tokenAddress: string;
    amount: number;
  }[],
  feePayerAddress: string,
  signTransaction: any,
  dispatchUpdate: (status: SendLocalTransactionStatus, err?: any) => void
) => {
  try {
    const instructions: TransactionInstruction[] = [];
    const feePayer = new PublicKey(feePayerAddress);

    await Promise.all(
      SPLTransfers.map(
        async ({ fromAddress, toAddress, tokenAddress, amount }) => {
          const mintPublicKey = new PublicKey(tokenAddress);
          const fromWallet = new PublicKey(fromAddress);
          const toWallet = new PublicKey(toAddress);

          const fromInstruction = await getCreateAtaInstruction(
            mintPublicKey,
            feePayer,
            fromWallet
          );
          if (fromInstruction) {
            instructions.push(fromInstruction);
          }
          const toInstruction = await getCreateAtaInstruction(
            mintPublicKey,
            feePayer,
            toWallet
          );
          if (toInstruction) {
            instructions.push(toInstruction);
          }
        }
      )
    );
    if (instructions.length > 0) {
      const transaction = new Transaction().add(...instructions);
      transaction.feePayer = feePayer;
      const blockhash = await privateConnection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash.blockhash;
      transaction.lastValidBlockHeight = blockhash.lastValidBlockHeight;
      dispatchUpdate("CONFIRMING_ATA", "Could not confirm the transaction");

      const signedTransaction: Transaction = await signTransaction(transaction);

      const txid = await sendAndConfirmRawTransaction(
        privateConnection,
        signedTransaction.serialize(),
        // {
        //   blockhash: blockhash.blockhash,
        //   lastValidBlockHeight: blockhash.lastValidBlockHeight,
        //   "signature":signedTransaction.so.
        // },
        {
          commitment: "confirmed",
        }
      );
      const confirmed = await privateConnection.confirmTransaction(
        {
          signature: txid,
          blockhash: blockhash.blockhash,
          lastValidBlockHeight: blockhash.lastValidBlockHeight,
        },
        "finalized"
      );
      console.log("Finished creating atas", txid, confirmed);
      if (confirmed?.value?.err === null) {
        return true;
      } else {
        dispatchUpdate("FAILED_ATA", "Could not confirm the transaction");
      }
    } else {
      return true;
    }
  } catch (err) {
    console.error("Failed to create ata", err);
    dispatchUpdate("FAILED_ATA", "Could not confirm the transaction");
    return false;
  }
};

const getCreateAtaInstruction = async (
  mintPublicKey: PublicKey,
  payer: PublicKey,
  destinationWallet: PublicKey
) => {
  const associatedDestinationTokenAddr = await getAssociatedTokenAddress(
    mintPublicKey,
    destinationWallet
  );

  const receiverAccount = await privateConnection.getAccountInfo(
    associatedDestinationTokenAddr
  );
  if (!receiverAccount) {
    return createAssociatedTokenAccountInstruction(
      payer,
      associatedDestinationTokenAddr,
      destinationWallet,
      mintPublicKey,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    );
  }
  return null;
};

const getATA = async ({
  mintPublicKey,
  ownerPublicKey,
}: {
  mintPublicKey: PublicKey;
  ownerPublicKey: PublicKey;
}) => {
  const associatedDestinationTokenAddr = await getAssociatedTokenAddress(
    mintPublicKey,
    ownerPublicKey
  );

  const receiverAccount = await privateConnection.getAccountInfo(
    associatedDestinationTokenAddr
  );
  if (receiverAccount) return associatedDestinationTokenAddr;
  return null;
};
